import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import { Outlet, Link } from "react-router-dom";
import Mobilemenu from './Mobilemenu';

export default function Header({menu,setmenu}) {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(true); 
  const[menushow,setMenushow]=useState(false)
  const[layer,setLayer]=useState(false)


 

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setMobileMenu(false); 
    } else {
      setMobileMenu(true);  
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const MenuShow = () => {
    setMenushow(!menushow);  
    setLayer(!layer)
  };

  return (
    <header className={scrolled ? 'fixed_header' : ''}>
            {layer?<div className="empty"></div>:""}



      <div className="container">
        <a aria-label=""  href='#' className="logo">
          {/* <img src={require('./Assets/logo.png')} alt="Logo" /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="48" viewBox="0 0 100 48" fill="none">
<g clipPath="url(#clip0_30_183)">
<path d="M6.76069 44.5915C6.76069 43.1402 6.77387 41.6356 6.81341 40.7834H6.77387C6.43123 42.1549 5.25832 45.3904 4.38853 47.8936H3.33423C2.6753 45.8298 1.47603 42.1815 1.12021 40.7701H1.08067C1.14657 41.6888 1.17292 43.4331 1.17292 44.7912V47.8936H0.0527344V39.9313H1.83186C2.66212 42.3146 3.6637 45.377 3.92727 46.4289H3.94045C4.12495 45.6034 5.32422 42.2614 6.19401 39.9313H7.92042V47.8936H6.76069V44.5915Z" fill="white"/>
<path d="M23.5108 44.232H19.6231V46.8683H23.893L23.748 47.8936H18.4634V39.9313H23.6953V40.9565H19.6231V43.22H23.5108V44.2453V44.232Z" fill="white"/>
<path d="M33.698 39.9313H36.5841C38.8113 39.9313 40.3137 41.4625 40.3137 43.8325C40.3137 46.2026 38.8509 47.8936 36.5314 47.8936H33.6848V39.9313H33.698ZM34.8973 46.8816H36.4655C38.2446 46.8816 39.0749 45.5901 39.0749 43.8458C39.0749 42.3413 38.3105 40.9432 36.4655 40.9432H34.8973V46.8949V46.8816Z" fill="white"/>
<path d="M51.5157 39.9313V47.8936H50.3164V39.9313H51.5157Z" fill="white"/>
<path d="M68.2922 45.59C67.9495 46.9748 66.9216 48 65.1029 48C62.7176 48 61.5315 46.2025 61.5315 43.939C61.5315 41.6755 62.7308 39.8114 65.1556 39.8114C67.0665 39.8114 68.0813 40.9165 68.3054 42.248H67.1061C66.8557 41.4358 66.3154 40.7967 65.1029 40.7967C63.4424 40.7967 62.7703 42.3146 62.7703 43.8991C62.7703 45.4835 63.4029 47.0014 65.1556 47.0014C66.3417 47.0014 66.8425 46.2824 67.1061 45.5767H68.3054L68.2922 45.59Z" fill="white"/>
<path d="M79.4016 45.63L78.6109 47.8936H77.4248L80.2055 39.9313H81.6815L84.5808 47.8936H83.3157L82.4986 45.63H79.4148H79.4016ZM82.1955 44.6048C81.4838 42.6076 81.0885 41.5024 80.9172 40.8766C80.7195 41.5823 80.2714 42.9005 79.7047 44.6048H82.2087H82.1955Z" fill="white"/>
<path d="M94.1882 39.9313H95.3875V46.8816H99.4597L99.3016 47.9069H94.1882V39.9446V39.9313Z" fill="white"/>
<path d="M49.552 29.732C47.1008 31.2766 44.2146 32.1687 41.1176 32.1687C32.3274 32.1687 25.1978 24.9653 25.1978 16.0843C25.1978 7.20333 32.3143 0 41.1176 0C44.2146 0 47.1139 0.892094 49.552 2.43662C45.6116 5.65881 43.0944 10.572 43.0944 16.0843C43.0944 21.5967 45.6116 26.5098 49.552 29.732Z" fill="white"/>
<path d="M25.7775 24.3529H9.22501L23.9061 5.33928C24.4332 4.66023 23.9456 3.67493 23.1022 3.67493H3.72949C3.51863 3.67493 3.30778 3.78145 3.18917 3.96785L1.31779 6.7906C1.02786 7.22999 1.33097 7.81584 1.85812 7.81584H14.9446L0.223959 26.8294C-0.303189 27.5085 0.171244 28.4938 1.02786 28.4938H28.8217C27.6225 27.2821 26.5945 25.8841 25.7775 24.3529Z" fill="white"/>
<path d="M97.9177 7.6028L99.8154 5.15286C100.277 4.5537 99.8549 3.68823 99.1037 3.68823H72.7332C73.9324 4.89988 74.9604 6.29794 75.7774 7.82915H90.6825L75.817 27.0425C75.3821 27.6017 75.7247 28.4006 76.3968 28.4938H76.5286H85.9909H97.1533C97.3773 28.4938 97.5882 28.3739 97.6936 28.1742L99.3278 25.3115C99.565 24.8855 99.2619 24.3529 98.7875 24.3529H84.9893L92.3035 14.8727L97.9308 7.58948L97.9177 7.6028Z" fill="white"/>
<path d="M60.4638 0C51.6604 0 44.5439 7.20333 44.5439 16.0843C44.5439 24.9653 51.6736 32.1687 60.4638 32.1687C69.254 32.1687 76.3837 24.9653 76.3837 16.0843C76.3837 7.20333 69.254 0 60.4638 0ZM69.2145 18.1348H62.4933V24.9254H58.4343V18.1348H51.7132V14.0338H58.4343V7.24327H62.4933V14.0338H69.2145V18.1348Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_30_183">
<rect width="100" height="48" fill="white"/>
</clipPath>
</defs>
</svg>
        </a>

        {
          mobileMenu ? (
            <>
              <Navigation />
              <a aria-label=""  href='tel:+917418013888' className='call_btn'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/bold/call">
                    <g id="call">
                      <path id="Vector" d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z" fill="white"/>
                      <path id="Vector_2" d="M21.9701 18.33C21.9701 18.61 21.9201 18.9 21.8201 19.18C21.7901 19.26 21.7601 19.34 21.7201 19.42C21.5501 19.78 21.3301 20.12 21.0401 20.44C20.5501 20.98 20.0101 21.37 19.4001 21.62C19.3901 21.62 19.3801 21.63 19.3701 21.63C18.7801 21.87 18.1401 22 17.4501 22C16.4301 22 15.3401 21.76 14.1901 21.27C13.0401 20.78 11.8901 20.12 10.7501 19.29C10.3601 19 9.9701 18.71 9.6001 18.4L12.8701 15.13C13.1501 15.34 13.4001 15.5 13.6101 15.61C13.6601 15.63 13.7201 15.66 13.7901 15.69C13.8701 15.72 13.9501 15.73 14.0401 15.73C14.2101 15.73 14.3401 15.67 14.4501 15.56L15.2101 14.81C15.4601 14.56 15.7001 14.37 15.9301 14.25C16.1601 14.11 16.3901 14.04 16.6401 14.04C16.8301 14.04 17.0301 14.08 17.2501 14.17C17.4701 14.26 17.7001 14.39 17.9501 14.56L21.2601 16.91C21.5201 17.09 21.7001 17.3 21.8101 17.55C21.9101 17.8 21.9701 18.05 21.9701 18.33Z" fill="white"/>
                    </g>
                  </g>
                </svg>
                +917418013888
              </a>
            </>
          ) : (
           <button onClick={()=>MenuShow()} className='mobile_menu_icon' >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>

           </button>
          )
        }

<Mobilemenu menu={menu} setmenu={setmenu} menushow={menushow} setMenushow={setMenushow} layer={layer} setlayer={setLayer}></Mobilemenu>

       
      </div>
    </header>
  );
}
